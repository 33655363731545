.home-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
}


.nav-container,
.footer-container {
    flex: 0 0 92px;
}


.body-test {
    flex: 1;
}
